var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container merch-card"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"prop":"version","label":"最新版本号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"latestSerialNumber","label":"最新序列号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"minSerialNumber","label":"最小序列号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"","label":"应用端","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.channel == 1)?_c('span',[_vm._v("用户端")]):_vm._e(),(row.channel == 2)?_c('span',[_vm._v("助手端")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"","label":"安卓/IOS","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.deviceType == 1)?_c('span',[_vm._v("安卓")]):_vm._e(),(row.deviceType == 2)?_c('span',[_vm._v("IOS")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"downloadUrl","label":"下载链接","align":"center"}}),_c('el-table-column',{attrs:{"prop":"address","label":"更新说明"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.explain),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(index + 1)+"."+_vm._s(item)+" ")])})}}])}),_c('el-table-column',{attrs:{"prop":"","label":"发布状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.releaseStatus == 0)?_c('span',[_vm._v("否")]):_vm._e(),(row.releaseStatus == 1)?_c('span',[_vm._v("是")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"btsed",on:{"click":function($event){return _vm.compile(scope.row)}}},[_vm._v("编辑")])]}}])})],1),_c('el-dialog',{attrs:{"title":"编辑","visible":_vm.compileVisible,"close-on-click-modal":false,"width":"560px"},on:{"update:visible":function($event){_vm.compileVisible=$event}}},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"},[_vm._v("最新版本号"),_c('span',[_vm._v("*")])]),_c('el-input',{attrs:{"placeholder":"例如 V1.3.4"},model:{value:(_vm.ruleForm.version),callback:function ($$v) {_vm.$set(_vm.ruleForm, "version", $$v)},expression:"ruleForm.version"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"},[_vm._v("最新序列号"),_c('span',[_vm._v("*")])]),_c('el-input',{attrs:{"maxlength":"10","placeholder":"开发提供，例如20241020"},on:{"input":function($event){_vm.ruleForm.latestSerialNumber = _vm.ruleForm.latestSerialNumber.replace(
            /[^0-9]/g,
            ''
          )}},model:{value:(_vm.ruleForm.latestSerialNumber),callback:function ($$v) {_vm.$set(_vm.ruleForm, "latestSerialNumber", $$v)},expression:"ruleForm.latestSerialNumber"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"},[_vm._v("最小序列号"),_c('span',[_vm._v("*")])]),_c('el-input',{attrs:{"maxlength":"10","placeholder":"用户版本低于最小序列号则需要强更"},on:{"input":function($event){_vm.ruleForm.minSerialNumber = _vm.ruleForm.minSerialNumber.replace(
            /[^0-9]/g,
            ''
          )}},model:{value:(_vm.ruleForm.minSerialNumber),callback:function ($$v) {_vm.$set(_vm.ruleForm, "minSerialNumber", $$v)},expression:"ruleForm.minSerialNumber"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"},[_vm._v("下载链接"),_c('span',[_vm._v("*")])]),_c('el-input',{attrs:{"placeholder":"请输入下载链接"},model:{value:(_vm.ruleForm.downloadUrl),callback:function ($$v) {_vm.$set(_vm.ruleForm, "downloadUrl", $$v)},expression:"ruleForm.downloadUrl"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"},[_vm._v("更新说明"),_c('span',[_vm._v("*")])]),_c('el-input',{attrs:{"placeholder":"说明1"},model:{value:(_vm.ruleForm.account1),callback:function ($$v) {_vm.$set(_vm.ruleForm, "account1", $$v)},expression:"ruleForm.account1"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"}),_c('el-input',{attrs:{"placeholder":"说明2"},model:{value:(_vm.ruleForm.account2),callback:function ($$v) {_vm.$set(_vm.ruleForm, "account2", $$v)},expression:"ruleForm.account2"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"}),_c('el-input',{attrs:{"placeholder":"说明3"},model:{value:(_vm.ruleForm.account3),callback:function ($$v) {_vm.$set(_vm.ruleForm, "account3", $$v)},expression:"ruleForm.account3"}})],1),_c('div',{staticClass:"form"},[_c('div',{staticClass:"lable"}),_c('el-input',{attrs:{"placeholder":"说明4"},model:{value:(_vm.ruleForm.account4),callback:function ($$v) {_vm.$set(_vm.ruleForm, "account4", $$v)},expression:"ruleForm.account4"}})],1),_c('div',{staticClass:"visible-footer"},[_c('el-button',{on:{"click":function($event){_vm.compileVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }