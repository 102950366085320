<template>
  <div class="app-container merch-card">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="version" label="最新版本号" align="center">
      </el-table-column>
      <el-table-column
        prop="latestSerialNumber"
        label="最新序列号"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="minSerialNumber" label="最小序列号" align="center">
      </el-table-column>
      <el-table-column prop="" label="应用端" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.channel == 1">用户端</span>
          <span v-if="row.channel == 2">助手端</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="安卓/IOS" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.deviceType == 1">安卓</span>
          <span v-if="row.deviceType == 2">IOS</span>
        </template></el-table-column
      >
      <el-table-column prop="downloadUrl" label="下载链接" align="center">
      </el-table-column>
      <el-table-column prop="address" label="更新说明">
        <template slot-scope="{ row }">
          <div v-for="(item, index) in row.explain" :key="index">
            {{ index + 1 }}.{{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="发布状态" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.releaseStatus == 0">否</span>
          <span v-if="row.releaseStatus == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="btsed" @click="compile(scope.row)">编辑</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="编辑"
      :visible.sync="compileVisible"
      :close-on-click-modal="false"
      width="560px"
    >
      <div class="form">
        <div class="lable">最新版本号<span>*</span></div>
        <el-input
          v-model="ruleForm.version"
          placeholder="例如 V1.3.4"
        ></el-input>
      </div>
      <div class="form">
        <div class="lable">最新序列号<span>*</span></div>
        <el-input
          maxlength="10"
          @input="
            ruleForm.latestSerialNumber = ruleForm.latestSerialNumber.replace(
              /[^0-9]/g,
              ''
            )
          "
          v-model="ruleForm.latestSerialNumber"
          placeholder="开发提供，例如20241020"
        ></el-input>
      </div>
      <div class="form">
        <div class="lable">最小序列号<span>*</span></div>
        <el-input
          maxlength="10"
          @input="
            ruleForm.minSerialNumber = ruleForm.minSerialNumber.replace(
              /[^0-9]/g,
              ''
            )
          "
          v-model="ruleForm.minSerialNumber"
          placeholder="用户版本低于最小序列号则需要强更"
        ></el-input>
      </div>
      <div class="form">
        <div class="lable">下载链接<span>*</span></div>
        <el-input
          v-model="ruleForm.downloadUrl"
          placeholder="请输入下载链接"
        ></el-input>
      </div>
      <div class="form">
        <div class="lable">更新说明<span>*</span></div>
        <el-input v-model="ruleForm.account1" placeholder="说明1"></el-input>
      </div>
      <div class="form">
        <div class="lable"></div>
        <el-input v-model="ruleForm.account2" placeholder="说明2"></el-input>
      </div>
      <div class="form">
        <div class="lable"></div>
        <el-input v-model="ruleForm.account3" placeholder="说明3"></el-input>
      </div>
      <div class="form">
        <div class="lable"></div>
        <el-input v-model="ruleForm.account4" placeholder="说明4"></el-input>
      </div>
      <div class="visible-footer">
        <el-button @click="compileVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      tableData: [],
      compileVisible: false,
      ruleForm: {},
    };
  },
  created() {
    this.appUpgradeListApi();
  },
  methods: {
    ...mapActions(["appUpgradeList", "appUpgradeEdit"]),
    async appUpgradeListApi() {
      let res = await this.appUpgradeList();
      if (res.code == "200") {
        res.data.forEach((item) => {
          this.$set(item, "explain", []);
          item.remark.forEach((v) => {
            if (v.length > 0) {
              item.explain.push(v);
            }
          });
        });
        this.tableData = res.data;
      }
    },
    compile(data) {
      let row = JSON.parse(JSON.stringify(data));
      this.$set(row, "account1", "");
      this.$set(row, "account2", "");
      this.$set(row, "account3", "");
      this.$set(row, "account4", "");
      if (row.remark.length > 0) {
        row.account1 = row.remark[0];
      }
      if (row.remark.length > 1) {
        row.account2 = row.remark[1];
      }
      if (row.remark.length > 2) {
        row.account3 = row.remark[2];
      }
      if (row.remark.length > 3) {
        row.account4 = row.remark[3];
      }
      this.ruleForm = row;
      this.compileVisible = true;
    },
    async submitForm() {
      if (this.ruleForm.version.length == 0) {
        return this.$message.error("请输入最新版本号");
      }
      if (this.ruleForm.latestSerialNumber.length == 0) {
        return this.$message.error("请输入最新序列号");
      }
      if (this.ruleForm.minSerialNumber.length == 0) {
        return this.$message.error("请输入最小序列号");
      }
      if (this.ruleForm.downloadUrl.length == 0) {
        return this.$message.error("请输入下载链接");
      }
      if (
        this.ruleForm.account1.length == 0 &&
        this.ruleForm.account2.length == 0 &&
        this.ruleForm.account3.length == 0 &&
        this.ruleForm.account4.length == 0
      ) {
        return this.$message.error("请输入最少一条更新说明");
      }
      if (
        (this.ruleForm.account1.trim() === "" &&
          this.ruleForm.account1.length > 0) ||
        (this.ruleForm.account2.trim() === "" &&
          this.ruleForm.account2.length > 0) ||
        (this.ruleForm.account3.trim() === "" &&
          this.ruleForm.account3.length > 0) ||
        (this.ruleForm.account4.trim() === "" &&
          this.ruleForm.account4.length > 0)
      ) {
        return this.$message.error("输入内容不能全为空格！");
      }
      this.ruleForm.remark = [
        this.ruleForm.account1,
        this.ruleForm.account2,
        this.ruleForm.account3,
        this.ruleForm.account4,
      ];
      let res = await this.appUpgradeEdit(this.ruleForm);
      if (res.code == "200") {
        this.$message.success("操作成功");
        this.appUpgradeListApi();
        this.compileVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .lable {
    width: 100px;
    font-weight: bold;
    min-width: 100px;
    span {
      color: #f53f3f;
      margin-left: 4px;
    }
  }
}
.visible-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
